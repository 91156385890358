<template>
  <v-row style="min-height: 120px;">
    <v-col cols="6">
      <v-row align="center" justify="start" class="px-2">
        <img v-if="match.team0" :src="match.team0.logo" width="48">
        <avatar-field
          v-else
          :active="false"
          custom-size="48"
          custom-icon="none"
        />
        <v-tooltip top color="white">
          <template #activator="{ on, attrs }">
            <h5 class="name-team font-weight-medium ml-2 text-truncate" v-bind="attrs" v-on="on" v-text="team0Name" />
          </template><div class="tooltip-text pa-2" v-text="team0Name" />
        </v-tooltip>
        <v-icon
          v-if="match.status === 'notStarted'"
          class="ml-3"
          color="primary"
          @click="teamIndex = 0; showEditTeamOriginMatchDialog = true"
          v-text="'create'"
        />
      </v-row>
    </v-col>
    <v-col cols="6">
      <v-row align="center" justify="start" class="px-2">
        <img v-if="match.team1" :src="match.team1.logo" width="48">
        <avatar-field
          v-else
          :active="false"
          custom-size="48"
          custom-icon="none"
        />
        <v-tooltip top color="white">
          <template #activator="{ on, attrs }">
            <h5 class="name-team font-weight-medium ml-2 text-truncate" v-bind="attrs" v-on="on" v-text="team1Name" />
          </template>
          <div class="tooltip-text pa-2" v-text="team1Name" />
        </v-tooltip>
        <v-icon
          v-if="match.status === 'notStarted'"
          class="ml-3"
          color="primary"
          @click="teamIndex = 1; showEditTeamOriginMatchDialog = true"
          v-text="'create'"
        />
      </v-row>
    </v-col>
    <edit-team-or-origin-match-dialog
      v-if="showEditTeamOriginMatchDialog"
      :organization-id="organizationId" :project-id="projectId" :match="match" :team-index="teamIndex" @closeDialog="showEditTeamOriginMatchDialog = false"
    />
  </v-row>
</template>
<script>
import i18n from '@/plugins/i18n'

export default {
  name: 'EditOriginMatch',
  components: {
    EditTeamOrOriginMatchDialog: () => import('@/modules/competition/matches/EditTeamOrOriginMatchDialog'),
  },
  props: {
    match: { type: Object, required: true },
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data: () => ({ showEditTeamOriginMatchDialog: false, teamIndex: 0 }),
  computed: {
    team0Name: ({ match }) => match.team0 ? match.team0.name : i18n.$t('project.competition.TBD'),
    team1Name: ({ match }) => match.team1 ? match.team1.name : i18n.$t('project.competition.TBD'),
  },
}
</script>
